const TimelineCardAndDate = ({ setSelectedCard, card, card: { title, company, icon }, side }) => {
    
    const TimelineDate = () => <p className="TimelineDate">{card.endDate}</p>

    const TimelineCard = () =>(
        <div className="TimelineCard">
        <p>{title}</p>
        <p>{company}</p>
        <div className="TimeLineCardFooter">
            <div className="TimelineCardCompanyIconContainer">
                <img className="TimelineCardCompanyIcon" src={icon} alt={`${company} logo`} />

            </div>
            <p onClick={() => { document.getElementById("myTimelineModal").style.display = "flex"; setSelectedCard(card) }} className="TimelineCardReadMore">Read More!</p>
        </div>
    </div>
    )
    
    return (
    <div className="TimelineCardContainer">
        {side === "right" ? (<><TimelineDate/><TimelineCard/></>) : (<><TimelineCard/><TimelineDate/></>)}
    </div>
)}

export default TimelineCardAndDate;