import data from '../data/music.json'

const Music = () => {
  const MusicCell = ({ track }) => (
    <div className="MusicCell">
      <iframe title={track} frameborder="no" src={`https://w.soundcloud.com/player/?url=${track}&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`} />
    </div>
  )

  return (
    <div className="MusicPage">
      <a className="MusicHeader" href={data.profile}>I make beats</a>
      <div className="SoundCloudSongs">

        {data.tracks.map(track => (<MusicCell track={track} />))}

      </div>
    </div>
  )
}
export default Music;