import { Link } from 'react-router-dom';
import { motion } from "framer-motion";


const HomePage = () => {

  const HomePageIcon = ({ iconName }) => (
    <div className="HomepageIconContainer">
      <Link to={`/${iconName}`} style={{ textDecoration: 'none' }}><img className="HomepageIcon" src={`${iconName}.png`} alt="coder logo" /> </Link>
      <Link to={`/${iconName}`} style={{ textDecoration: 'none' }}><p className="HomepageIconText">{iconName.toUpperCase()}</p> </Link>
    </div>
  )

  const HomepageTitle = () => (<motion.div
    animate={{
      y: ['0px', '-20px', '0px']
    }}
  >
    <h1 className="HomepageTitle">
      Rohit Saigal
    </h1>
  </motion.div>)
  return (
    <div className="Homepage">
      <div className="HomepageIcons">
        <HomePageIcon iconName="experience" />
        <HomepageTitle />
        <HomePageIcon iconName="skills" />
      </div>
      <div className="HomepagePictureContainer">
        <img src="makinbeats.jpeg" alt="Rohit making music with his cat on his lap" className="HomepagePicture" />
      </div>

      <div className="HomepageIconsBottom">
        <HomePageIcon iconName="education" />
        <HomePageIcon iconName="music" />
      </div>
    </div>
  )
}

export default HomePage;