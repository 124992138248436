
const SoftwareEducation = () => {
    return (
    <div className="Education">
        <img src="uiucbig.png" alt="UIUC logo" className="EducationPicture" />
        <div className="EducationDegree">
            <p >Bachelor of Science in Liberal Arts and Science
            <br/> In 
            <br/> Mathematics and Computer Science
            <br/> 2017
            </p>
            </div>
    </div>
    )
}

export default SoftwareEducation;