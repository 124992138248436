import data from '../../data/software.json'
import TimelineCardAndDate from './Card';
import { useState } from 'react';

const SoftwareExperience = () => {
    const leftCards = [];
    const rightCards = [];
    const [selectedCard, setSelectedCard] = useState({ title: "", company: "", icon: "", accomplishments: [] })

    data["Professional Experience"].forEach((card, index) => {
        if (index % 2 === 1) {
            leftCards.push(<div className="TimelineCardSpace" />);
            rightCards.push(<TimelineCardAndDate card={card} setSelectedCard={setSelectedCard} side="right" />)
        }
        else {
            leftCards.push(<TimelineCardAndDate card={card} setSelectedCard={setSelectedCard} side="left"/>)
            rightCards.push(<div className="TimelineCardSpace" />);

        }
    });

    return (
        <div className="SoftwareTimeline">
            <div className="Timeline">
                <div className="TimelineCardsColumn">
                    {leftCards}
                </div>
                <div className="TimelineVerticalLine" />
                <div className="TimelineCardsColumn">
                    {rightCards}
                </div>
            </div>
            <div id="myTimelineModal" className="TimelineModal">
                <div class="TimelineModalContent">
                    <span onClick={() => document.getElementById("myTimelineModal").style.display = "none"} id="MyTimelineModalClose" class="TimelineModalClose">&times;</span>
                    <div className="TimelineCardHeader">
                    <div className="TimelineCardTitleandCompany">
                        <p>{selectedCard.company}</p>
                        <p>{selectedCard.title}</p>
                    </div>
                    <img className="TimelineCardCompanyIcon" src={selectedCard.icon} alt={`${selectedCard.company} logo`} />
                        
                    </div>
                    <hr/>
                    <ul className="TimelineCardAccomplishments">
                        {selectedCard.accomplishments.map(accomplishment=>(<li>{accomplishment}</li>))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SoftwareExperience;