import data from '../data/software.json'


const SoftwareSkills = () => {
    const dataSkills = data["Skills"];

    const Skill = ({url, skill}) => (
    <div onClick={()=>window.location=url} className="SoftwareSkillCardContainer">
      <img 
      className="SoftwareSkillIcon" 
      src={`${skill.replace('.', '').replace(' ', '').replace('#','sharp').toLowerCase()}.png`}
      alt={`${skill}`} />
     <p className="SoftwareSkillCardSkillName">{skill}</p>
    </div>
    )

    const SkillCard = ({skillSetName, skillSet}) => (
      (
        <div className="SoftwareSkillCard">
          <p className="SoftwareSkillCardTitle">{skillSetName}</p>
          <div className="SoftwareSkillCardSkills">
            {skillSet.map(skillData =>(<Skill {...skillData}/>))}
          </div>
        </div>
        )
    )
    const skillCards = Object.keys(dataSkills).map(skillSetName => (<SkillCard skillSet={dataSkills[skillSetName]} skillSetName={skillSetName}/>))
    return (<div className="SoftwareSkillCards">{skillCards}</div>)
  }

export default SoftwareSkills