import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './sass/Sass.scss';
import HomePage from './pages/HomePage'
import Music from './pages/Music'
import Skills from './pages/Skills';
import Experience from './pages/Timeline';
import Education from './pages/Education';


const Portfolio = () => {



    const NavigationItem = ({pageName}) => (<Link to={`/${pageName}`} style={{ textDecoration: 'none' }}><p className="NavigationItem" >{pageName.toUpperCase()}</p></Link>)

    const Navigation = () =>
      <div className="Navigation">
        <Link to="/home"><img className="NavigationIcon" src="software.png" alt="logo of a developer"/></Link>
        <NavigationItem pageName="home"/>
        <NavigationItem pageName="experience"/>
        <NavigationItem pageName="education"/>
        <NavigationItem pageName="skills"/>
        <NavigationItem pageName="music"/>
      </div>

  const Footer = () => (
    <footer className="Footer">
      <a href="mailto:rohitnsaigal@gmail.com">rohitnsaigal@gmail.com</a>
      <a href="tel:4124772435">412-477-2435</a>
      <div className="FooterIconCredit">Icons made by
       <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a>,
      <a href="https://www.freepik.com" title="Freepik">Freepik</a>, <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons</a>,
       and <a href="https://www.flaticon.com/authors/good-ware" title="Good Ware">Good Ware</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    </footer>)

  return (<Router>
    <div className="App">
      < Navigation />
      <div className="Section">
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/home" exact>
            <HomePage />
          </Route>
          <Route path="/experience" exact>
            <Experience />
          </Route>
          <Route path="/education" exact>
            <Education />
          </Route>
          <Route path="/skills" exact>
            <Skills />
          </Route>
          <Route path="/music" exact>
            <Music />
          </Route>
        </Switch>
      </div>
      <Footer />
    </div>
    </Router>
  );
}

export default Portfolio;
